<template>
    <div class="login-wrap">
        <div class="ms-login">

            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <div v-if="Zhhao == 1">
                    <div class="ms-title">账号密码登录</div>
                    <el-form-item prop="username">
                        <el-input v-model="param.username" placeholder="请输入账号">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            id="password" 
                            placeholder="请输入密码"
                            v-model="param.password"
                            @keyup.enter="submitForm()"
                            autocomplete="off"
                        >
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm()">登录</el-button>
                    </div>
                </div>
                <div v-if="Zhhao == 2" class="Zhh2">
                    <div class="ms-title">验证码登录</div>
                    <el-form-item prop="username" class="Z_forom">
                        <el-input v-model="param.username" placeholder="username">
                            <template #prepend>
                                <el-button icon="el-icon-user"></el-button>
                            </template>
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input
                            type="password"
                            placeholder="password"
                            v-model="param.password"
                            @keyup.enter="submitForm()"
                        >
                            <template #prepend>
                                <el-button icon="el-icon-lock"></el-button>
                            </template>
                        </el-input>
                        <span class="huoqu">获取验证码</span>
                    </el-form-item>
                    <div class="login-btn">
                        <el-button type="primary" @click="submitForm()">登录</el-button>
                    </div>
                </div>
                <div v-if="Zhhao == 3" class="Zhh2">
                    <div class="ms-title">微信扫码登录注册</div>
                    <img class="Imui" src="" alt="">
                    <div class="login-btn fdfdfdf">
                       请使用微信扫描二维码登录
                    </div>
                </div>
                <div class="Log_tt">
                    <div v-if="Zhhao == 1">
                        <span @click="Zhhao = 3">二维码登录</span>
                        <span @click="Zhhao = 2">验证码登录</span>
                    </div>
                    <div v-if="Zhhao == 2">
                        <span @click="Zhhao = 3">二维码登录</span>
                        <span @click="Zhhao = 1">账号密码登录</span>
                    </div>
                    <div v-if="Zhhao == 3">
                        <span @click="Zhhao = 1">账号密码登录</span>
                        <span @click="Zhhao = 2">验证码登录</span>
                    </div>
                </div>
            </el-form>
            <img class="Tyyy" src="../assets/img/immleft.png" alt="">
        </div>   
    </div>
</template>
  
<script>
// const API_PROXY = 'http://system-api.duxin365.com/'
axios.defaults.headers.post['Content-Type'] = 'application/json'
import axios from 'axios'
export default {
    data() {
        return {
            Zhhao:1,
            param: {
                username: "admin",
                password: "admin123",
                
            },
            rules: {
                username: [
                    { required: true, message: "请输入用户名", trigger: "blur" }
                ],
                password: [
                    { required: true, message: "请输入密码", trigger: "blur" }
                ]
            },
        };
    },
    created() {
        this.$store.commit("clearTags");
    },
    mounted: function () {
        var that = this
        let token = window.localStorage.getItem("accessToken")
        if (token) {
            that.$router.push({path:'/dashboard'})
        }
        that.param.username = ''
        that.param.password = ''
       console.log(typeof window.chrome)
        if (typeof window.chrome === 'undefined') {
            $('#password').attr('type', 'password');
        }
    },
    methods: {
        submitForm() {
            axios.post('/admin/index/login', {
                username: this.param.username,
                password: this.param.password,
            }).then(res => {
                if (res.data.code === 0) {
                    console.log(res.data)
                    this.$message.error(res.data.msg);
                } else {
                    if (res.data.data.userinfo.token) {
                        var token = res.data.data.userinfo.token
                        localStorage.setItem("accessToken", token);
                    }
                    this.$refs.login.validate(valid => {
                        
                        if (valid) {
                            this.$message.success("登录成功");
                            localStorage.setItem("ms_username", this.param.username);
                            this.$router.push("/");
                        } else {    
                            this.$message.error("请输入账号和密码");
                            return false;
                        }
                    });
                }
            })
        }
    }
};
</script>


<style>
#password {
    text-security: disc;
    -webkit-text-security: disc;
}
.login-wrap .fdfdfdf {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #BBBBBB;
    margin-top: 5px;
}
.login-wrap .Imui {
    width: 146px;
    height: 146px;
    background: #D1D1D1;
    margin-left: 70px;
}
.login-wrap .huoqu {
    width: 90px;
    height: 40px;
    background: #F0F0F0;
    border-radius: 4px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
    float: right;
    position: absolute;
    right: 8px;
    cursor: pointer;
}
.login-wrap .el-input-group__append, .el-input-group__prepend {
    background: #fff;
    padding: 0 15px;
}
.login-wrap .Zhh2 .el-input__inner{
    width: 130px!important;
    border-left: none;
    padding-left: 0;
}
.Zhh2 .Z_forom .el-input__inner{
    width: 232px!important;
    border-left: none;
    padding-left: 0;
}
.login-wrap .Log_tt div span {
    display: inline-block;
    width: 49%;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
    margin-top: 30px;
    cursor: pointer;
}
.login-wrap .Log_tt div span:first-child {
    border-right: 1px solid #D1D1D1;
}
.login-wrap .Log_tt {
    margin-top: 50px;
    height: 80px;
    border-top: 1px solid #EEEEEE;
    position: absolute;
    bottom: 15px;
    width: 33%;
}
.ms-login .Tyyy {
    width: 463px;
    height: 348px;
    margin: 67px 0 0 1px;
}
.el-form-item {
    width: 288px;
    margin-bottom: 24px;
}
.login-wrap .el-input__inner, .login-wrap .el-textarea__inner {
    width: 232px!important;
}
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #595959;
    margin-bottom: 18px;
    margin-top: 30px;
}
.ms-login {
    position: absolute;
    left: 35%;
    top: 44%;
    width: 870px;
    height: 480px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
    float: right;
    padding-right: 62px;

}
.login-btn {
    width: 288px;
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>